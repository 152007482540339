<template>
  <li>
    {{change.item}}
    <ul style="list-style-type: circle" v-if="typeof change.changes !== 'undefined'">
        <li v-for="(item, k) in change.changes" :key="`p${k}`">
          {{ item.item }}
          <ul style="list-style-type: circle" v-if="typeof item.changes !== 'undefined'">
            <li v-for="(child, i) in item.changes" :key="`c${i}`">
              {{child.item}}
            </li>
          </ul>
        </li>
    </ul>
  </li>
</template>

<script>
// import ChangeList from "./ChangeList.vue";

export default {
  name: "ChangeListItem",
  // components: {ChangeList},
  props: ['change']
}
</script>

<style scoped>

</style>
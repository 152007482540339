<template>
  <ul style="list-style-type: circle">

    <ChangeListItem v-for="(listItem,k) in changes" :key="k" :change="listItem"/>
  </ul>
</template>

<script>
import ChangeListItem from "./ChangeListItem.vue";

export default {
  name: "ChangeList",
  components: {ChangeListItem},
  props: ['changes']
}
</script>

<style scoped>

</style>
 <template>
   <div class="contentContainer">
    <div class="card">
      <div class="card-body">
        <b-tabs type="is-toggle" expanded>
          <b-tab class="tabTitle" :title="this.$t('changelog.server')" icon="server">
            <div class="card">
              <div class="card-body">
<!--                <div v-html="serverChangelog"></div>-->
                <div v-for="(change,k) in serverChangelog" :key="k">
                  <div class="title"><h2>Version {{change.version}} ({{dotsToDate(change.date)}})</h2></div>
                  <ChangeList key="ch1" :changes="change.change_list" />
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab :title="this.$t('changelog.web_client')" icon="globe">
            <div class="card">
              <div class="card-body">
<!--                <div v-html="webChangelog"></div>-->
                <div v-for="(change,k) in webChangelog" :key="k">
                  <div class="title"><h2>Version {{change.version}} ({{dotsToDate(change.date)}})</h2></div>
                  <ChangeList key="ch2"  :changes="change.change_list" />
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>

<!--     <b-loading v-model="isLoading"></b-loading>-->

   </div>
  </template>

<script>
import {mapState} from "vuex";
import moment from "moment/moment";
import ChangeList from "../components/ChangeList.vue";

export default {
  name: "ServerChangelog",
  components: {ChangeList},
  data() {
    return {
      isLoading: false
    }
  },
  methods:{
    dotsToDate(date){
      let tempDate = new Date(date)
      return moment(tempDate).format('DD.MM.YYYY');
    },
    getData(locale) {
      Promise.all([
        this.$store.dispatch('ui/getChangelog', locale),
        // this.$store.dispatch('ui/getServerChangelog'),
        // this.$store.dispatch('ui/getWebChangelog')
      ]).finally(() => {
        this.isLoading = false;
      })
    }
  },
  computed: {
    ...mapState({
      serverChangelog: state => state.ui.serverChangelog,
      webChangelog: state => state.ui.webChangelog
    }),
    locale() {
      if(this.$i18n.locale === 'ua') {
        return 'uk';
      }
      return this.$i18n.locale
    }
  },
  mounted() {
    this.isLoading = true;
    this.getData(this.locale);
  },
  watch: {
    '$i18n.locale': function () {
       this.getData(this.locale)
    }
  }
}
</script>

<style >

/*.tabTitle{*/
/*  color: rgb(64, 153, 255);*/
/*}*/
iframe{
  width: 100%;
  height: calc(100vh - 60px);
}
.contentContainer{
  margin-top: 1.5rem;
}
h2{
  margin-top: 1rem;
  color: #000;
  font-size: 24px;
}
.contentContainer .title {
  padding-inline-start: 0 !important;
}
ul {
  padding-inline-start: 20px;
}
</style>